import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import OpenMenuButton from "../OpenMenuButton/OpenMenuButton";

const AboutMenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: -10;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: 1s;
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
    display: none;
  }
`
const AboutMenuTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  opacity: 1;
  transition: 0.5s ease-in-out;
  margin-bottom: 110px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-top: 50px;
  gap: 5px;
    ::-webkit-scrollbar {
    display: none;
  }
`;

const AboutMenuText = styled.p`
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 32px;
  font-size: 40px;
  font-weight: 700;
  scrollbar-width: none;
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin: 0;
  
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 25px;
    width: 100%;
    max-width: 70vw;
    text-wrap: wrap;

  }
`

const AboutMenu = (): JSX.Element => {
  const { showMenu } = useSelector((state: any) => state.showMenu);
  const { aboutMenu } = useSelector((state: any) => state.aboutMenu);

  const aboutMenuRef = useRef<any>();
  const aboutMenuTextRef = useRef<any>();

  const scrollTop = () =>{
    if (aboutMenuTextRef.current) {
      aboutMenuTextRef.current.scrollTo(0,0)
    }
  }

  const tranformText = (text: string) => {
    if(text) {
      return text.split("\n").filter(item => item.trim() !== "\n").map((item: string) => item.trim())
    } else {
      return []
    }
  }

  useEffect(() => {
    if(showMenu && aboutMenuRef.current) {
      setTimeout(() => {
      aboutMenuRef.current.style.zIndex = "100";
      aboutMenuRef.current.style.display = "flex";
      aboutMenuRef.current.style.opacity = "100";
      }, 1)
    } else {
      setTimeout(() => {
      aboutMenuRef.current.style.opacity = "0";
      aboutMenuRef.current.style.diplay = "none";
      aboutMenuRef.current.style.zIndex = "-10";
      }, 1)
    }
    scrollTop();
  }, [showMenu])

  return <AboutMenuContainer  className={"ABOUTMENU"} ref={aboutMenuRef}>
    <AboutMenuTextContainer>
      {aboutMenu && tranformText(aboutMenu?.info).map((text, index) => {
        return <AboutMenuText key={index} ref={aboutMenuTextRef}>{text}</AboutMenuText>
      })}
    </AboutMenuTextContainer>
    <OpenMenuButton image={'images/logo_mosca_white.png'}/>
  </AboutMenuContainer>
};

export default AboutMenu;
