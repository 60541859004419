import { useDispatch } from "react-redux";
import styled from "styled-components";
import { closeProjectShowingActionCreator } from "../../../redux/ProjectShowingSlice";
import { ProjectProps } from "../../../types/types";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Mousewheel } from "swiper";
import { useEffect, useState } from "react";
import getMobileOperatingSystem from "../../../utils/getMobileOperatingSystem";

interface ProjectContainerProps {
  backgroundImage?: string;
};

const ProjectTitle = styled.div<{ device: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 37px;
  font-size: 49px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  letter-spacing: -3px;
  margin: 20px 20px 10px;
  font-weight: 600;

  @media (max-width: 768px) {
    opacity: 1;
    margin: 20px 10px 10px;
    font-size: 35px;
    line-height: 27px;
    padding-top: 5px;
  }
`

const ProjectTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  opacity: 1;
  transition: 0.5s ease-in-out;
  gap: 0;

  height: calc(100% - 220px);
  overflow-y: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    width: 70% !important;
  }
`;

const ProjectText = styled.pre`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  line-height: 16px;
  font-size: 15px;
  margin: 0;
  margin-bottom: 10px;
  white-space: pre-wrap;
`;

const ProjectContainer = styled.div<ProjectContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: #fff;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  color: #000;
`;

const Closing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 30vw;
  min-height: 200px;
  font-weight: 600;
  font-size: 16px;
  word-spacing: 15px;
  background-color: #fff;
  @media (max-width: 768px) {
    min-width: 90%;
    max-width: 90%;
  }
`;

const OpenProject = ({project}: ProjectProps): JSX.Element => {
  const dispatch = useDispatch();
  const [opSystem, setOpSystem] = useState<string>("")

  const close = () => {
    dispatch(closeProjectShowingActionCreator())
  }
  useEffect(() => {
    setOpSystem(getMobileOperatingSystem())
  }, [])


  const mayusCompleteDescription = project?.completeDescription?.toUpperCase();
  const mayusCompleteTeam = project?.completeTeam?.toUpperCase();

  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Mousewheel]}
        className="mySwiper"
        mousewheel={{sensitivity: 0.2, releaseOnEdges: true, forceToAxis: true}}
      >
        <SwiperSlide>
        <ProjectContainer>
          <ProjectTitle device={opSystem}>{project.name}</ProjectTitle>
          <ProjectTextContainer>
          <ProjectText>{mayusCompleteDescription}</ProjectText>
          <ProjectText>{mayusCompleteTeam}</ProjectText>
          </ProjectTextContainer>
        </ProjectContainer>
        </SwiperSlide>
        <>
        {Object.values(project?.images).map((image: string, index: number) => 
          <SwiperSlide>
          <ProjectContainer backgroundImage={image as string}>
          </ProjectContainer>
          </SwiperSlide>
        )}
        <SwiperSlide>
        <Closing onClick={close}>{`X   CLOSE`}</Closing>

        </SwiperSlide>
        </>
      </Swiper>
    </>
  )
}

export default OpenProject;
