import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setProjectShowingActionCreator } from "../../../redux/ProjectShowingSlice";
import { ProjectContainerProps, ProjectProps } from "../../../types/types";
import { useEffect, useState } from "react";
import getMobileOperatingSystem from "../../../utils/getMobileOperatingSystem";

const ProjectTitle = styled.div<{ device: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 37px;
  font-size: 49px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  letter-spacing: -3px;
  margin: 20px 20px 10px;
  font-weight: 600;

  @media (max-width: 768px) {
    opacity: 1;
    margin: 20px 10px 10px;
    font-size: 35px;
    line-height: 27px;
    padding-top: 5px;
  }
`
const ProjectTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  gap: 0;

  @media (max-width: 768px) {
    opacity: 1;
    width: 70% !important;    
  }
`;

const ProjectText = styled.pre`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  margin: 0;
  white-space: pre-wrap;
`;

const ViewMoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  font-family: Helvetica, sans-serif;
  margin-top: 30px;
  cursor: pointer;
`

const ProjectContainer = styled.div<ProjectContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: #fff;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  color: ${({ color }) => color};

  :hover {
    ${ProjectTitle}, ${ProjectTextContainer} {
      opacity: 1;
      @media (max-width: 768px) {
        opacity: auto; 
      }
    }
  }
`;

const Project = ({project}: ProjectProps): JSX.Element => {
  const dispatch = useDispatch();
  const [opSystem, setOpSystem] = useState<string>("")

  useEffect(() => {
    setOpSystem(getMobileOperatingSystem())
  }, [])

  const viewMore = () => {
    dispatch(setProjectShowingActionCreator(project))
  }

  const mayusFrontDescription = project?.frontDescription?.toUpperCase()
  const mayusFrontTeam = project?.frontTeam?.toUpperCase()

  return (
  <ProjectContainer color={project.textColor} backgroundImage={project.frontImage}>
    <ProjectTitle device={opSystem}>{project.name}</ProjectTitle>
    <ProjectTextContainer>
      <ProjectText>{mayusFrontDescription}</ProjectText>
      <ProjectText>{mayusFrontTeam}</ProjectText>
      <ViewMoreButton onClick={viewMore}>VIEW MORE</ViewMoreButton>
    </ProjectTextContainer>
  </ProjectContainer>
  )
}

export default Project;
