import { createSlice } from "@reduxjs/toolkit";

const Projects = createSlice({
  name: "projects",
  initialState: {
    projects: [],
  },
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    }
  }
});

export const { setProjects: setProjectsActionCreator } = Projects.actions;

export default Projects.reducer;