import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AboutMenu from "./components/AboutMenu/AboutMenu";
import IntroAnimation from "./components/IntroAnimation/IntroAnimation";
import OpenProject from "./components/MainGallery/OpenProject/OpenProject";
import OpenMenuButton from "./components/OpenMenuButton/OpenMenuButton";
import { setProjectsActionCreator } from "./redux/ProjectsSlice";
import SwiperGallery from "./components/MainGallery/SwiperGallery";
import { AppContainerProps } from "./types/types";
import getMobileOperatingSystem from "./utils/getMobileOperatingSystem";
import axios from "axios";
import { setAboutMenuInfoActionCreator } from './redux/AboutMenuSlice';
import { hideIntroActionCreator } from './redux/HideIntroAnimationSlice';


const AppContainer = styled.div<AppContainerProps>`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  height: ${(props) => props.device === "iOS" ? "-moz-available" : "100vh"};
  height: ${(props) => props.device === "iOS" ? "-webkit-fill-available" : "100vh"};
  height: ${(props) => props.device === "iOS" ? "fill-available" : "100vh"};
  min-height: ${(props) => props.device === "iOS" ? "-webkit-fill-available" : "none"};
`;

function App() {
  const projectToShow = useSelector((state: any) => state.projectShowing.project);
  const dispatch = useDispatch();
  const ref = useRef<any>();
  const [mobileOS, setMobileOS] = useState<any>(null);
  const [startSliderAutoplay, setStartSliderAutoplay] = useState<boolean>(false);
  const [info, setInfo] = useState<string>("");
  const [textShown, setTextShown] = useState(false);

  useEffect(()=>{
    if (textShown) {
      console.log('CODE DEVELOPED BY ALEJANDRO MACHIN')
    }
      setTextShown(true)
  }, [textShown])

  useEffect(() => {
    const fetchingData = async () => {
      await axios.get(`${process.env.REACT_APP_API_URL}intro`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}` 
          }
        }
      )
      .then((response) => {
        const beautifiedData = response.data.data?.map((project: any) => ({...project.attributes, id: project.id}));
        setInfo(beautifiedData[0]?.info);
      })

      await axios.get(`${process.env.REACT_APP_API_URL}projects`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}` 
          }
        }
      )
      .then((response) => {
        const beautifiedData = response.data.data?.map((project: any) => ({...project.attributes, id: project.id}));
        dispatch(setProjectsActionCreator(beautifiedData));
        dispatch(hideIntroActionCreator())
      })

      await axios.get(`${process.env.REACT_APP_API_URL}about`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}` 
          }
        }
      )
      .then((response) => {
        const beautifiedData = response.data.data?.map((menuInfo: any) => ({...menuInfo.attributes, id: menuInfo.id}));
        dispatch(setAboutMenuInfoActionCreator(beautifiedData[0]));
      })
    }
    fetchingData();
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      setStartSliderAutoplay(true)
    }, 2000)
  })

  useEffect(() => {
    setMobileOS(getMobileOperatingSystem())
  }, [dispatch])

  return (
  <AppContainer ref={ref} className="APP" device={mobileOS}>
    <IntroAnimation info={info} />
    {projectToShow.id && <OpenProject project={projectToShow}/>}
    {startSliderAutoplay && <SwiperGallery /> }
    <AboutMenu/>
    <OpenMenuButton/>
  </AppContainer>
  )
}

export default App;
