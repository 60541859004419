import { createSlice } from "@reduxjs/toolkit";

const ShowMenuSlice = createSlice({
  name: "showMenu",
  initialState: {
    showMenu: false,
  },
  reducers: {
    toggleMenu: (state) => {
      state.showMenu = !state.showMenu;
    }
  }
});

export const { toggleMenu: toggleMenuActionCreator } = ShowMenuSlice.actions;

export default ShowMenuSlice.reducer;