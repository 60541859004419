import { configureStore } from '@reduxjs/toolkit'
import ShowMenuSliceReducer from './ShowMenuSlice'
import ProjectShowingSliceReducer from './ProjectShowingSlice'
import ProjectsSliceReducer from './ProjectsSlice'
import AboutMenuSliceReducer from './AboutMenuSlice'
import HideIntroMenuSliceReducer from './HideIntroAnimationSlice'


export const store = configureStore({
  reducer: {
    showMenu: ShowMenuSliceReducer,
    projectShowing: ProjectShowingSliceReducer,
    projects: ProjectsSliceReducer,
    aboutMenu: AboutMenuSliceReducer,
    hideIntroMenu: HideIntroMenuSliceReducer
  },
})