import { createSlice } from "@reduxjs/toolkit";

const Projects = createSlice({
  name: "aboutMenu",
  initialState: {
    aboutMenu: {},
  },
  reducers: {
    setInfo: (state, action) => {
      state.aboutMenu = action.payload;
    }
  }
});

export const { setInfo: setAboutMenuInfoActionCreator } = Projects.actions;

export default Projects.reducer;