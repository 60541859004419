import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Mousewheel } from "swiper";
import { useSelector } from "react-redux";
import Project from "./Project/Project";
import { ProjectInterface } from "../../types/types";

const SwiperGallery = () => {
  const { projects } = useSelector((state: any) => state.projects);

  return (
      <>
        <Swiper
          slidesPerView={"auto"}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Mousewheel]}
          className="mySwiper"
          mousewheel={{sensitivity: 0.2, releaseOnEdges: true, forceToAxis: true}}
        >
              {projects?.map((project: ProjectInterface, index: number) => (
                <SwiperSlide key={project.id}>
                  <Project project={project as any}/>
                </SwiperSlide>
            ))}
        </Swiper>
      </>
    );
}

export default SwiperGallery
