import { createSlice } from "@reduxjs/toolkit";

const ProjectShowing = createSlice({
  name: 'projectShowing',
  initialState: {
    showing: false,
    project: {},
  },
  reducers: {
    setProjectShowing: (state, action) => {
      state.project = action.payload;
      state.showing = true;
    },
    closeProjectShowing: (state) => {
      state.project = {};
      state.showing = false;
    },
  }
});

export const { setProjectShowing: setProjectShowingActionCreator, closeProjectShowing: closeProjectShowingActionCreator } = ProjectShowing.actions;

export default ProjectShowing.reducer;
