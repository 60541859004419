import { createSlice } from "@reduxjs/toolkit";

const HideIntroMenuSlice = createSlice({
  name: "hideIntroMenu",
  initialState: {
    hideIntroMenu: false,
  },
  reducers: {
    hideIntro: (state) => {
      state.hideIntroMenu = true;
    }
  }
});

export const { hideIntro: hideIntroActionCreator } = HideIntroMenuSlice.actions;

export default HideIntroMenuSlice.reducer;