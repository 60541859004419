import { useDispatch } from "react-redux";
import styled from "styled-components";
import { toggleMenuActionCreator } from "../../redux/ShowMenuSlice";


const CenterContainer = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100px;
  right: calc(50vw - 50px);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`
const OpenButton = styled.img<{ imageSet?: boolean; }>`
  width: ${props => props.imageSet ? "80px" : "60px"};
  height: ${props => props.imageSet ? "80px" : "60px"};
  z-index: 19;
  color: #000;
  border: none;
  cursor: pointer;
`

interface OpenMenuButtonProps {
  image?: string;
}

const OpenMenuButton = ({image}: OpenMenuButtonProps): JSX.Element => {
  const dispatch = useDispatch();
  const imageSrc = image ? image : "images/logoT.png";
  const toggleMenu = () => {
    dispatch(toggleMenuActionCreator());
  };

  return (
    <CenterContainer>
      <OpenButton src={imageSrc} imageSet={imageSrc === "images/logoT.png" ? false : true} onClick={toggleMenu}/>
    </CenterContainer>
  );
};

export default OpenMenuButton;