import { useEffect, useRef, useState } from "react"
import { useSelector } from 'react-redux'
import styled from "styled-components"

const CenterContainer = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100px;
  right: calc(50vw - 50px);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`

const IntroAnimationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 100;
  transition: opacity 1s;
`
const AnimationText = styled.p`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height : 32px;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 768px) {
    padding: 0 20px;
    font-size: 24px;
    line-height: 20px;
  }
`
const Logo = styled.img`
  width: 80px;
  height: 80px;
  z-index: 19;
`
interface IntroAnimationProps {
  info: string
}

const IntroAnimation = ({info}: IntroAnimationProps): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const [showAnimation, setShowAnimation] = useState(true)
  const { hideIntroMenu } = useSelector((state: any) => state.hideIntroMenu)

  useEffect(() => {
    if (hideIntroMenu) {
    setTimeout(() => {
      if (container.current) {
        container.current.style.opacity = "0"
      }
    }, 6000)
    setTimeout(() => {
      setShowAnimation(false)
    }
    , 10000)
  }
  }, [hideIntroMenu])

  return <>
    {showAnimation && <IntroAnimationContainer ref={container}>
        <AnimationText>
          {info}
      </AnimationText>
      <CenterContainer>
      <Logo src="images/logo_mosca_white.png"/>
      </CenterContainer>
    </IntroAnimationContainer> }
  </>
}

export default IntroAnimation
